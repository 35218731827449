/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.

@import "~@angular/material/theming";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat-palette($mat-blue, 500);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Material design icons font path
$material-design-icons-font-path: "~material-design-icons-iconfont/dist/fonts/";

$white-color:#fff;