/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  font-size: 14px;
  letter-spacing: 1px;
  background: #f5f5f5;
}



.unmaze-mat-tab-body-wrapper{
  box-shadow: 0px 1px 3px #e2e2e2 !important;
  padding: 15px !important;
  border-radius: 0px !important;
  min-height: 400px !important;
  background-color: #fff;

}

