@charset "UTF-8";
/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
/*
 * Application global variables.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
@import url(~bootstrap/dist/css/bootstrap.min.css);
@import url(~font-awesome/css/font-awesome.min.css);
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("☺"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff") format("woff"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf") format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-display: block; }

/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card-title {
  font-size: 24px;
  font-weight: 500; }

.mat-card-header .mat-card-title {
  font-size: 20px; }

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px; }

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 14px;
  font-weight: 500; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; }

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75); }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75); }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75); } }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px; }

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-sub-label-error {
  font-weight: normal; }

.mat-step-label-error {
  font-size: 14px; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0; }

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px; }

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-base .mat-list-item {
  font-size: 16px; }
  .mat-list-base .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list-base .mat-list-option {
  font-size: 16px; }
  .mat-list-base .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-list-base[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list-base[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list-base[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list-base[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden;
  position: relative; }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }
  @media (-ms-high-contrast: active) {
    .mat-ripple-element {
      display: none; } }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {
  /*!*/ }

@keyframes cdk-text-field-autofill-end {
  /*!*/ }

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1); }

.mat-option {
  color: rgba(0, 0, 0, 0.87); }
  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87); }
  .mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3; }

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081; }

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336; }

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54); }
  .mat-pseudo-checkbox::after {
    color: #fafafa; }

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0; }

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081; }

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3; }

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336; }

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0; }

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-theme-loaded-marker {
  display: none; }

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-autocomplete-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white; }
    .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: rgba(0, 0, 0, 0.87); }

.mat-badge-content {
  color: white;
  background: #2196f3; }
  @media (-ms-high-contrast: active) {
    .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white; }

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336; }

.mat-badge {
  position: relative; }

.mat-badge-hidden .mat-badge-content {
  display: none; }

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38); }

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.mat-badge-content.mat-badge-active {
  transform: none; }

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px; }

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px; }

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px; }

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px; }

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px; }

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px; }

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px; }

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px; }

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px; }

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px; }

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px; }

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px; }

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px; }

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px; }

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px; }

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px; }

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px; }

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px; }

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px; }

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent; }
  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: #2196f3; }
  .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
    color: #ff4081; }
  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #f44336; }
  .mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: #2196f3; }
  .mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: #ff4081; }
  .mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: #f44336; }
  .mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent; }
  .mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
    opacity: 0.1;
    background-color: currentColor; }

.mat-button-focus-overlay {
  background: black; }

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12); }

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white; }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: white; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    color: white; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    color: white; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #2196f3; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    background-color: #ff4081; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    background-color: #f44336; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12); }
  .mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }

.mat-stroked-button:not([class*='mat-elevation-z']), .mat-flat-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-raised-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-raised-button[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-fab:not([class*='mat-elevation-z']), .mat-mini-fab:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']), .mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-fab[disabled]:not([class*='mat-elevation-z']), .mat-mini-fab[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none; }

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38); }
  .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white; }
  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
    background-color: black; }

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12); }

[dir='rtl'] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12); }

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54); }
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87); }

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee; }
  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background: white; }
  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd; }

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12); }

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
  .mat-card.mat-card-flat:not([class*='mat-elevation-z']) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-checkmark {
  fill: #fafafa; }

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important; }
  @media (-ms-high-contrast: black-on-white) {
    .mat-checkbox-checkmark-path {
      stroke: #000 !important; } }

.mat-checkbox-mixedmark {
  background-color: #fafafa; }

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2196f3; }

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081; }

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336; }

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0; }

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0; }

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54); }

@media (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5; } }

@media (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: none; } }

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: #2196f3; }

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #ff4081; }

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: #f44336; }

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87); }
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4; }
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
    opacity: 0.54; }
  .mat-chip.mat-standard-chip.mat-chip-disabled {
    opacity: 0.4; }
  .mat-chip.mat-standard-chip::after {
    background: black; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2196f3;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
    background: rgba(255, 255, 255, 0.1); }

.mat-table {
  background: white; }

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit; }

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54); }

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87); }

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54); }

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12); }

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent; }

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18); }

.mat-calendar-body-selected {
  background-color: #2196f3;
  color: white; }

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4); }

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #ff4081;
    color: white; }
  .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(255, 64, 129, 0.4); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #f44336;
    color: white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(244, 67, 54, 0.4); }
  .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-datepicker-toggle-active {
  color: #2196f3; }
  .mat-datepicker-toggle-active.mat-accent {
    color: #ff4081; }
  .mat-datepicker-toggle-active.mat-warn {
    color: #f44336; }

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
    background: white; } }

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87); }

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26); }
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    color: inherit; }

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6); }

.mat-hint {
  color: rgba(0, 0, 0, 0.6); }

.mat-form-field.mat-focused .mat-form-field-label {
  color: #2196f3; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: #ff4081; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #f44336; }

.mat-focused .mat-form-field-required-marker {
  color: #ff4081; }

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2196f3; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: #ff4081; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #f44336; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #2196f3; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081; }

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336; }

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336; }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #f44336; }

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336; }

.mat-error {
  color: #f44336; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02); }

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent; }

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12); }

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87); }

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2196f3; }

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081; }

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336; }

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336; }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06); }

.mat-icon.mat-primary {
  color: #2196f3; }

.mat-icon.mat-accent {
  color: #ff4081; }

.mat-icon.mat-warn {
  color: #f44336; }

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38); }

.mat-input-element {
  caret-color: #2196f3; }
  .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }

.mat-accent .mat-input-element {
  caret-color: #ff4081; }

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336; }

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336; }

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87); }

.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87); }

.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54); }

.mat-list-item-disabled {
  background-color: #eeeeee; }

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04); }

.mat-menu-panel {
  background: white; }
  .mat-menu-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87); }
  .mat-menu-item[disabled], .mat-menu-item[disabled]::after {
    color: rgba(0, 0, 0, 0.38); }

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04); }

.mat-paginator {
  background: white; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54); }

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54); }

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54); }

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-progress-bar-background {
  fill: #bbdefb; }

.mat-progress-bar-buffer {
  background-color: #bbdefb; }

.mat-progress-bar-fill::after {
  background-color: #2196f3; }

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ff80ab; }

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ff80ab; }

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081; }

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336; }

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #2196f3; }

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081; }

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336; }

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2196f3; }

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2196f3; }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081; }

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081; }

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336; }

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336; }

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38); }

.mat-radio-button .mat-ripple-element {
  background-color: black; }

.mat-select-value {
  color: rgba(0, 0, 0, 0.87); }

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42); }

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38); }

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54); }

.mat-select-panel {
  background: white; }
  .mat-select-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, 0.12); }

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2196f3; }

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081; }

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336; }

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336; }

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38); }

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-drawer.mat-drawer-push {
    background-color: white; }
  .mat-drawer:not(.mat-drawer-side) {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12); }
  .mat-drawer-side.mat-drawer-end {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none; }

[dir='rtl'] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none; }
  [dir='rtl'] .mat-drawer-side.mat-drawer-end {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12); }

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6); }

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081; }

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54); }

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081; }

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #2196f3; }

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(33, 150, 243, 0.54); }

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #2196f3; }

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336; }

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54); }

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336; }

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black; }

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa; }

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #2196f3; }

.mat-primary .mat-slider-thumb-label-text {
  color: white; }

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081; }

.mat-accent .mat-slider-thumb-label-text {
  color: white; }

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336; }

.mat-warn .mat-slider-thumb-label-text {
  color: white; }

.mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2); }

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12); }

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent; }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26); }

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7); }

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .mat-step-header:hover {
    background: none; } }

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54); }

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white; }

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white; }

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336; }

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87); }

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336; }

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white; }

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12); }

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-sort-header-arrow {
  color: #757575; }

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none; }

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87); }
  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87); }

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none; }

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3); }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2196f3; }

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3); }

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081; }

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336; }

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3); }

.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #2196f3; }

.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3); }

.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: #ff4081; }

.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #f44336; }

.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87); }
  .mat-toolbar.mat-primary {
    background: #2196f3;
    color: white; }
  .mat-toolbar.mat-accent {
    background: #ff4081;
    color: white; }
  .mat-toolbar.mat-warn {
    background: #f44336;
    color: white; }
  .mat-toolbar .mat-form-field-underline,
  .mat-toolbar .mat-form-field-ripple,
  .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor; }
  .mat-toolbar .mat-form-field-label,
  .mat-toolbar .mat-focused .mat-form-field-label,
  .mat-toolbar .mat-select-value,
  .mat-toolbar .mat-select-arrow,
  .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit; }
  .mat-toolbar .mat-input-element {
    caret-color: currentColor; }

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9); }

.mat-tree {
  background: white; }

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87); }

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-simple-snackbar-action {
  color: #ff4081; }

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
* {
  box-sizing: border-box; }

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  font-size: 14px;
  letter-spacing: 1px;
  background: #f5f5f5; }

.unmaze-mat-tab-body-wrapper, .mat-tab-body-wrapper {
  box-shadow: 0px 1px 3px #e2e2e2 !important;
  padding: 15px !important;
  border-radius: 0px !important;
  min-height: 400px !important;
  background-color: #fff; }

.custome-card-image {
  height: 130px;
  padding: 0px !important;
  margin-bottom: 10px; }
  .custome-card-image .row {
    height: 100%; }
  .custome-card-image .content {
    padding-top: 35px; }
  .custome-card-image .img {
    background-color: #2196f3;
    width: 100%;
    height: 100%;
    padding-top: 45px;
    border-radius: 4px 0px 0px 4px; }
    .custome-card-image .img img {
      height: 50px;
      width: 50px;
      margin: auto;
      display: block; }

.mat-tab-label {
  height: 40px !important;
  background-color: #2196f3;
  margin: 5px !important;
  color: #fff !important;
  margin-left: 0px !important;
  min-width: 140px !important;
  border-radius: 0px !important; }
  .mat-tab-label:hover {
    background-color: #1187e4 !important; }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important; }

.mat-tab-label-active {
  background-color: #1187e4 !important;
  opacity: 1 !important; }

.mat-list-base .mat-list-item {
  font-size: 14px;
  letter-spacing: 1px !important; }

.mat-tab-label, .mat-tab-link {
  font-size: 14px !important;
  letter-spacing: 1px !important; }

table {
  width: 100%; }

.mat-form-field {
  display: block !important; }

.form-disabled .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: #f3f3f3 !important; }

.form-disabled .custome-border {
  background-color: #f3f3f3 !important; }
  .form-disabled .custome-border:hover {
    border-color: rgba(0, 0, 0, 0.12) !important; }

.form-disabled .align-arrow-right {
  color: transparent !important; }

.form-disabled .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: transparent !important; }

.form-disabled .required-field {
  display: none !important; }

.form-disabled .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.12) !important; }

.form-disabled .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  border-width: 2px !important;
  color: rgba(0, 0, 0, 0.12) !important; }

.form-disabled .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: transparent !important; }

.form-disabled .file-upload .file-select {
  background-color: #f3f3f3 !important; }

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: transparent !important; }

.disabled {
  background-color: #f3f3f3 !important; }

td.disabled {
  background-color: #f3f3f3 !important; }

.mat-header-row {
  height: 40px !important; }

tr.mat-row {
  height: 45px !important; }

.flot-right-table-col .mat-sort-header-container {
  float: right !important; }

th.mat-header-cell,
td.mat-footer-cell {
  background-color: #fff;
  font-size: 1rem;
  color: #222;
  padding-left: 10.5px !important; }

.add_booking_table table {
  border-collapse: separate !important; }

.text-right {
  text-align: right !important; }

.auto-size-table td.mat-cell,
.auto-size-table td.mat-footer-cell,
.auto-size-table th.mat-header-cell {
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: auto !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: unset !important;
  font-size: 13px !important; }

.auto-size-table th.mat-header-cell,
.auto-size-table td.mat-footer-cell {
  background-color: #fff !important;
  font-size: 13px !important;
  color: #222 !important;
  font-weight: 600 !important; }

.auto-size-table .mat-header-row {
  height: 50px !important; }

.auto-size-table .mat-sort-header-arrow {
  color: #222 !important; }

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-right: 1px solid #e4e4e4 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  width: 160px !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap; }
  td.mat-cell:first-child,
  td.mat-footer-cell:first-child,
  th.mat-header-cell:first-child {
    min-width: 60px !important; }

.mat-sort-header-arrow {
  color: #222 !important; }

.col_no_padding {
  padding-left: 0px !important;
  padding-right: 0px !important; }

a:focus {
  outline: none; }

a:hover {
  text-decoration: none;
  cursor: pointer; }

button {
  outline: 0 !important; }

.mr-top-30 {
  margin-top: 30px; }

.outline_non:focus {
  outline: none; }

.label_custome {
  display: block !important;
  font-size: 15px !important;
  color: #6c757d !important; }

.custome_checkbox {
  margin-right: 15px; }

/** add floating icon **/
.add_branch_aad_icon {
  position: fixed;
  z-index: 999;
  right: 100px;
  bottom: 15%; }

.align-arrow-right {
  float: right;
  margin-top: -8px;
  color: #949494; }

.postion_input {
  position: absolute; }

.mat-checkbox-inner-container {
  height: 22px !important;
  width: 22px !important; }

.mat-checkbox-checkmark-path {
  stroke: #2196f3 !important; }

.mat-checkbox-frame {
  border-color: #2196f3 !important; }

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: transparent; }

.mat-radio-button {
  margin-right: 10px !important; }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2196f3 !important; }

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.5) !important;
  transform: rotate(45deg) !important; }

.mat-radio-outer-circle {
  box-sizing: border-box;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  transition: border-color ease 280ms;
  width: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 0% !important;
  border-color: #2196f3 !important; }

.mat-radio-inner-circle {
  border-radius: 0% !important;
  left: 6px !important;
  content: "";
  width: 8px !important;
  height: 14px !important;
  border: solid #2196f3 !important;
  border-width: 0 2px 2px 0 !important;
  transition: transform ease 280ms, background-color ease 280ms;
  transform: scale(0.001) !important; }

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: transparent !important; }

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: transparent !important; }

.mat-paginator-page-size-select .mat-form-field-wrapper {
  padding-bottom: 15px !important; }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background-color: rgba(123, 123, 123, 0.06); }

.mat-form-field-wrapper {
  padding-bottom: 15px !important; }

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
  border-width: 1px !important;
  transition: border-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #2196f3 !important; }

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  border-width: 2px !important;
  color: #1565c0 !important; }

h4 {
  font-size: 1rem !important;
  font-weight: bold; }

.delet_icon {
  color: #f44336 !important; }

.add_icon {
  color: #8bc34a !important; }

.submit_btn {
  width: 150px;
  display: block !important;
  margin: 0 auto !important;
  transition: 1s !important; }

.sub_heading {
  height: 40px !important;
  background-color: #fff !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
  .sub_heading .mat-list-base .mat-list-item,
  .sub_heading .mat-list-base .mat-list-option {
    display: inline-block !important;
    height: 30px !important; }
  .sub_heading .mat-list-base {
    padding-top: 0px !important; }
  .sub_heading .active-list-item {
    color: #2196f3 !important;
    border-bottom: 2px solid #2196f3 !important; }
  .sub_heading a {
    color: #343a40 !important;
    font-size: 13px !important; }
    .sub_heading a:hover {
      background-color: transparent !important;
      color: #2196f3 !important; }
    .sub_heading a:first-child {
      padding-left: 0 !important; }

.search_icon {
  font-size: 17px !important;
  color: #949494 !important; }

input.mat-chip-input {
  height: 25px !important; }

.mat-chip-not-approved {
  background-color: #ef5555 !important;
  color: #fff !important; }

.mat-chip-approved {
  background-color: #58a958 !important;
  color: #fff !important; }

.mat-chip.mat-standard-chip {
  background-color: #2196f3;
  color: #fff !important; }

.mat-standard-chip {
  min-height: 25px !important; }

.mat-chip {
  font-size: 12px !important;
  font-weight: 400 !important; }

.mat-chip-list-wrapper {
  margin: -9px !important; }

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #fff !important;
  opacity: 0.7 !important; }

.normal_table thead {
  background-color: #2196f3;
  color: #fff !important; }

.mat-button-toggle-group-appearance-standard,
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  border-radius: 8px !important; }

.mat-button-toggle-checked {
  background-color: #2196f3 !important; }

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #fff !important; }

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 38px !important;
  padding: 0 12px !important; }

.mat-button-toggle-button {
  width: 80px !important; }

@media only screen and (max-width: 1260px) {
  .sub_heading a {
    margin-right: 0px !important; }
  .mat-tab-label {
    min-width: 100px !important; }
  .border_right {
    border: none !important; } }

@media only screen and (max-width: 1368px) {
  .auto-size-table td.mat-cell,
  .auto-size-table td.mat-footer-cell,
  .auto-size-table th.mat-header-cell {
    white-space: nowrap !important; } }

@media (min-width: 1300px) {
  .table_responsive {
    max-width: 1270px !important; } }

@media (min-width: 1400px) {
  .table_responsive {
    max-width: 100% !important; } }

.edit_icon_color {
  color: #607d8b;
  cursor: pointer; }
  .edit_icon_color:hover {
    color: #2196f3; }

.delete_icon_color {
  color: #d94c58;
  cursor: pointer; }

.remark_icon_color {
  color: #43425d;
  cursor: pointer; }
  .remark_icon_color:hover {
    color: #2196f3; }

.pending {
  color: #ffc107; }

.approved {
  color: #8bc34a; }

.rejected {
  color: #d94c58; }

.inactive {
  color: #425a65; }

.paid {
  color: #2196f3; }

.hide_text {
  visibility: hidden; }

.edit_btn {
  background-color: #607d8b;
  color: #fff; }
  .edit_btn:hover {
    background-color: #425a65 !important; }

.link_color {
  color: #2196f3 !important; }

.reset_btn {
  background-color: #455a64 !important;
  color: #fff; }
  .reset_btn:hover {
    background-color: #34464e !important; }

.pending_btn {
  background-color: #ffc107;
  color: #fff; }

.approve_btn {
  background-color: #8bc34a;
  color: #fff; }
  .approve_btn:hover {
    background-color: #7cb835 !important; }
  .approve_btn:focus {
    color: #fff;
    background-color: #7cb835 !important;
    border-color: #7cb835; }

.approved-button {
  border: 1px solid #7cb835 !important;
  color: #7cb835 !important; }

.reject_btn {
  background-color: #d94c58 !important;
  color: #fff !important; }
  .reject_btn:hover {
    background-color: #be323e !important; }
  .reject_btn:focus {
    color: #fff;
    background-color: #be323e !important;
    border-color: #be323e; }

.de-active-button {
  background-color: #43425d !important;
  color: #fff !important; }
  .de-active-button:hover {
    background-color: #313053 !important; }
  .de-active-button:focus {
    color: #fff;
    background-color: #313053 !important;
    border-color: #313053; }

.mat-raised-button {
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 1px !important;
  height: 40px !important;
  box-shadow: none !important; }
  .mat-raised-button:hover {
    background-color: #1976d2;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important; }
  .mat-raised-button:focus {
    background-color: #1565c0;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important; }

.edit_view_form_card .mat-raised-button {
  margin-right: 20px !important; }

.mat-calendar-controls .mat-button {
  color: inherit !important; }

.mat-button {
  color: #d94c58 !important; }

.custom-file {
  margin-bottom: 1.34375em !important;
  height: 52px !important; }

.custom-file-label::after {
  position: absolute;
  display: block;
  content: "Upload" !important;
  background-color: #2196f3 !important;
  border-left: 1px solid #2196f3 !important;
  color: #fff;
  cursor: pointer;
  height: 48px !important;
  border-radius: 0 3px 3px 0 !important;
  line-height: 40px !important; }

.input-group > .custom-file {
  display: flex;
  align-items: center;
  height: 45px !important; }

.custom-file-input {
  position: relative;
  height: 55px !important; }

.custom-file-label {
  position: absolute;
  height: 50px !important;
  font-weight: 300 !important;
  line-height: 40px !important;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px !important;
  cursor: pointer; }

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none !important;
  outline: none !important;
  border: 2px solid #2196f3; }

.file-upload {
  display: block;
  text-align: center; }
  .file-upload .material-icons {
    display: inline-block;
    padding-top: 10px; }

.file-upload .file-select {
  display: block;
  border: 1px solid #ced4da;
  border-radius: 4px;
  color: #34495e;
  cursor: pointer;
  height: 52px;
  line-height: 50px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px; }

.file-upload .file-select .file-select-button {
  background: #2196f3;
  padding: 0 15px;
  display: inline-block;
  height: 50px;
  color: #fff; }

.file-upload .file-select .file-select-name {
  padding: 0px 0px 0px 65px;
  margin-top: -55px; }

.file-upload .file-select:hover {
  border-color: #2196f3;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out; }

.file-upload .file-select:hover .file-select-button {
  background: #2196f3;
  color: #fff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out; }

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out; }

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out; }

.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0); }

.file-upload .file-select.file-select-disabled {
  opacity: 0.65; }

.file-upload .file-select.file-select-disabled:hover {
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative; }

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 50px;
  line-height: 50px; }

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 50px;
  display: inline-block;
  padding: 0 10px; }

.close_modal_btn {
  bottom: 10px;
  float: right; }
  .close_modal_btn .close_modal_icon {
    color: #fff !important; }

/* width */
/* Track */
/* Handle */
/* Handle on hover */
.no_data_content img {
  width: 15% !important; }

.no_data_content h3 {
  font-weight: 300 !important;
  color: #43425d !important;
  font-size: 16px !important; }

.search-bar-border-design .border_style {
  border: 1px solid #d9d8d8;
  border-radius: 0px;
  margin-left: 0px !important;
  margin-right: 0px !important; }
  .search-bar-border-design .border_style .mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0, 0, 0, 0) !important; }
  .search-bar-border-design .border_style .mat-form-field-wrapper {
    padding-bottom: 0 !important; }
  .search-bar-border-design .border_style .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.45em 0 0.45em 0 !important; }
  .search-bar-border-design .border_style .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: -0.45em !important;
    height: 44px !important; }
  .search-bar-border-design .border_style input.mat-input-element {
    margin-top: 0.0725em !important; }
  .search-bar-border-design .border_style .align-arrow-right {
    margin-top: -5px !important;
    color: #949494;
    position: absolute !important;
    right: 0px !important; }
  .search-bar-border-design .border_style .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0%) !important; }
  .search-bar-border-design .border_style .mat-error {
    display: block;
    margin-top: 23px !important; }

.search-bar-border-design .padding_less {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.search-bar-border-design .width_full {
  width: 100% !important;
  height: 45px !important; }

.search-bar-border-design .no_border_radius {
  border-radius: 0px !important; }

.search-bar-border-design .right_border_radius {
  border-radius: 0 0px !important; }

.search-bar-border-design .border_right {
  border-right: 1px solid #d9d8d8 !important; }

.search-bar-border-design .mat-input-element {
  caret-color: #9e9e9e !important; }

.border_style {
  border: 1px solid #e4e4e4;
  border-radius: 10px 10px 0px 0px;
  margin-left: 0px !important;
  margin-right: 0px !important; }
  .border_style .mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0, 0, 0, 0) !important; }
  .border_style .mat-form-field-wrapper {
    padding-bottom: 0 !important; }
  .border_style .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.45em 0 0.45em 0 !important; }
  .border_style .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: -0.45em !important; }
  .border_style input.mat-input-element {
    margin-top: 0.0725em !important; }
  .border_style .align-arrow-right {
    margin-top: -5px !important;
    color: #949494;
    position: absolute !important;
    right: 0px !important; }
  .border_style .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0%) !important; }

.padding_less {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.width_full {
  width: 100% !important;
  height: 45px !important; }

.no_border_radius {
  border-radius: 0px !important; }

.right_border_radius {
  border-radius: 0px 7px 0px 0px !important; }

.border_right {
  border-right: 1px solid #e4e4e4 !important; }

.mat-input-element {
  caret-color: #9e9e9e !important; }

.avatar-upload {
  position: relative;
  margin: 50px auto;
  text-align: center; }
  .avatar-upload .material-icons {
    padding-top: 5px;
    padding-left: 2px; }

.avatar-upload .avatar-edit {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 65%; }

.avatar-upload .avatar-edit input {
  display: none; }

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out; }

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6; }

.avatar-upload .avatar-edit input + label:after {
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto; }

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin: auto; }

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.width_150 {
  width: 150px !important; }

.add_view_form_card {
  box-shadow: none !important; }

.mat-sort-header-button[disabled] ~ .mat-sort-header-arrow {
  display: none; }

.required-field {
  color: #d94c58;
  position: absolute; }

.custome-box-border {
  padding: 10px !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 30.9%;
  margin: 0 1.2%;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }

.custome-box-border-two {
  padding: 16px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 47.7%;
  margin: 0 1.1%;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }

.custome-box-border-1 {
  padding: 16px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 64%;
  margin: 0 1.1%;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }

.custome-box-border-full {
  padding: 16px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 99%;
  margin: 1.1%;
  margin-left: 19px;
  margin-right: 26px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }

#re-size-check-box .mat-radio-button:last-child {
  margin-left: 5px !important; }

.custome-border {
  border: 1px solid #e0e0e0;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  padding: 0 10px;
  margin-bottom: 15px; }
  .custome-border:hover {
    border-color: #2196f3 !important; }
  .custome-border .mat-radio-button:last-child {
    margin-left: 50px !important; }

.right-text {
  float: right;
  font-weight: bold; }

.custome-modal-header {
  position: relative; }
  .custome-modal-header .close_modal_btn {
    bottom: 0px;
    position: absolute !important;
    right: 0; }

.mat-dialog-container {
  padding: 15px !important;
  overflow: hidden !important;
  max-width: 100vw !important; }

.cdk-overlay-pane {
  max-width: 100vw !important; }

.mat-dialog-actions {
  min-height: 70px !important; }

.setting-section .card {
  height: 400px; }
  .setting-section .card .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%; }

.card .card-header {
  height: 60px; }
  .card .card-header .card-title {
    line-height: 40px; }
  .card .card-header .close_modal_btn {
    bottom: 50px !important;
    float: right; }
    .card .card-header .close_modal_btn .close_modal_icon {
      color: #000 !important; }

.card .card-footer {
  height: 60px; }
  .card .card-footer .mat-raised-button {
    height: 35px !important; }

.data-name {
  width: 70%;
  display: inline-block;
  white-space: normal; }

.custome-spinner {
  position: absolute !important;
  z-index: 999 !important;
  top: 50% !important;
  left: 50% !important; }

.viewDetail-close-button {
  left: 95%;
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3; }

.view-header-align {
  position: inherit !important;
  margin-left: 30px !important; }

.view-header {
  padding-left: 20px;
  padding-top: 8px;
  font-size: 18px;
  font-weight: bold; }

.alert-button-close-icon {
  float: right; }

.material-icons.md-30 {
  font-size: 25px !important; }

.input-width {
  width: 100% !important; }

.image-center {
  display: block !important;
  margin: 0 auto !important; }

hr {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

#vendor_name_width {
  width: 350px !important;
  word-wrap: break-word !important;
  white-space: normal !important; }

.mat-option-text {
  display: inline-block;
  flex-grow: 1;
  overflow: initial !important; }

.mat-autocomplete-panel {
  min-width: 300px !important; }

.mat-option {
  overflow: initial !important; }

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #000001 !important; }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6) !important; }

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.85) !important; }

.export-div {
  margin-top: -1px;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-bottom: 10px; }
  .export-div .mat-icon {
    position: absolute !important;
    font-size: 20px !important; }
  .export-div span {
    padding-left: 30px; }
  .export-div a {
    color: #6b7074 !important;
    padding: 6px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.26);
    margin-left: 1px; }

.profile-titel {
  padding-left: 5%;
  font-size: 20px;
  font-weight: 500;
  position: absolute; }

.account-summary {
  background-color: #dcf0ff; }

.modal-heading-span {
  display: inline-block;
  margin-top: 10px;
  font-weight: 700; }

.payment-receipt-submit-div {
  padding-top: 10px;
  border-top: 1px solid #e0e0e0; }

.add-from-headeing {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px; }

.multi-invoice .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0.25em 0; }

.multi-quick-payment .date {
  min-width: 150px !important; }

.multi-quick-payment .account-master {
  min-width: 300px !important; }

.multi-quick-payment .add-less {
  width: 60px;
  padding-top: 20px;
  text-align: center; }

.multi-quick-payment .stickyend {
  right: 0px;
  position: sticky;
  z-index: 1;
  background: #fff; }

.multi-quick-payment .table td,
.multi-quick-payment .table th {
  padding: 5px 1px !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

.multi-quick-payment .table {
  margin-bottom: 5px !important; }

.pay_order_btn {
  height: 30px !important;
  line-height: 30px !important;
  padding: 0 10px !important; }

.amount-column .mat-sort-header-button {
  text-align: right !important; }

.amount-column .mat-sort-header-container {
  float: right !important; }

.mat-sort-header-button {
  text-align: justify !important; }

.from-border-less_table .mat-form-field-wrapper {
  padding-bottom: 0 !important; }

.from-border-less_table .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0) !important; }

.from-border-less_table .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.45em 0 0.45em 0 !important; }

.from-border-less_table .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background-color: rgba(123, 123, 123, 0.06) !important;
  border-radius: 10px; }

.from-border-less_table .align-arrow-right {
  float: right;
  margin-top: -8px !important;
  color: #949494; }

.from-border-less_table .mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 !important;
  height: 48px !important; }

.from-border-less_table .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 !important; }

.from-border-less_table .mat-form-field-infix {
  border-top: 15px solid transparent; }

.from-border-less_table .width_30 {
  width: 30px !important; }
  .from-border-less_table .width_30 .mat-form-field-infix {
    width: 30px !important; }

.from-border-less_table .width_50 {
  width: 100px !important; }
  .from-border-less_table .width_50 .mat-form-field-infix {
    width: 100px !important; }

.from-border-less_table .width_100 .mat-form-field-infix {
  width: 180px !important; }

.list-group-item {
  border: none !important; }

.mt_celar_btn {
  margin-top: 60px; }

.btn-full-width {
  width: 100%; }

th.mat-header-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-right: 1px solid #d9d8d8 !important;
  border-right-width: 1px !important; }

.separator {
  box-shadow: 2px 0px 0px #f7f5f5; }

.fixed-footer {
  margin-top: 25px !important; }

/** Radio button Size issus Css **/
.editRadioButton {
  background-color: #f3f3f3 !important; }
  .editRadioButton .mat-radio-button {
    padding: 15px 0 9px 0 !important; }

/*------ Tooltip font size incerease--------*/
.mat-tooltip {
  font-size: 12px !important; }

/** Radio button Size issus Css **/
.ngx-doc-viewer {
  height: 350px !important;
  width: 100% !important; }

.img-container {
  height: 350px !important;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  display: table-cell;
  width: 400px !important; }

.mt-8 {
  margin-top: 5rem !important; }

.mb-8 {
  margin-bottom: 5rem !important; }

.table_caption {
  display: none !important; }

/** code by kanika **/
.mar-x-0 {
  margin-right: 0px;
  margin-left: 0px; }

/** css for sidebar**/
.mat-list-item {
  /** color: #000 !important;**/ }
  .mat-list-item .mat-list-icon {
    /** height: 30px;
    font-size: 22px;**/
    padding: 0px !important; }

.mat-drawer-side {
  border-right: none; }

/**  table css **/
.top-border-table {
  margin-top: 10px;
  border-top: 1px solid #d9d8d8 !important; }
  .top-border-table .mat-icon {
    cursor: pointer; }

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-bottom: 1px solid #d9d8d8;
  border-right: 0px !important;
  border-left: 0px !important;
  border-top: 0px; }

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #efefef; }

/** main container **/
.mat-drawer-content {
  background-color: #efefef; }

/** topbar **/
.mat-elevation-z6 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.border_box {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  padding: 10px; }
